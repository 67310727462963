import "./App.css";
import animation_optimized from "../src/json/animation_optimized.json";
import { createRef, useEffect } from "react";
import { Player } from "@lottiefiles/react-lottie-player";

function App() {
  const player = createRef();
  useEffect(() => {
    setTimeout(() => {
      player.current.pause();
    }, 4000);
  }, [player]);

  return (
    <div className="flex justify-center items-center h-screen w-custom mx-auto">
      <Player ref={player} autoplay loop src={animation_optimized}></Player>
    </div>
  );
}

export default App;
